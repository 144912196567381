<template>
  <div class="home">
    <div class="m_box" v-loading="loading" element-loading-text="连接中">
      <!-- <div class="buttons">
        <h1>操作栏</h1>
        <div>       
        </div>
      </div> -->
      <div class="mescroll_box">
        <div v-if="status == 0" class="tip_box">
            <h2 style="text-align: center;margin-top:25px">远程问诊</h2>
            <div class="demo-input-suffix" style="margin-top: 150px">
              
              <el-input placeholder="请输入userid" size="medium" v-model="owner.userId"></el-input>
            </div>
            <div class="demo-input-suffix" style="margin-top: 45px">
              
              <el-input placeholder="请输入咨询单ID" size="medium" v-model="advisoryId"></el-input>
            </div>
            <div class="buttons_item" style="text-align: center;margin-top: 45px">
              <el-button type="primary" @click="startConsult1">开始咨询</el-button>
            </div>
        </div>
        <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
          <div class="scrollBox">
            <div class="chatScroll">
              <div class="chatItem" v-for="item in chatList">
                <div class="user" v-if="item.owner.userId == userInfo.uid">
                  <div class="icon">
                    <div class="userIco">
                      <img :src="userInfo.avatar" alt="" />
                      <!-- <span>{{ item.owner.userName }}</span> -->
                    </div>
                  </div>
                  <div class="message_container">
                    <messageType :item="item"></messageType>
                    <div class="loading" v-if="item.done == 1" v-loading="item.done == 1">发送中</div>
                    <div class="loading" v-if="item.done == 3">
                      发送失败
                      <span @click="resend(item)">点击重试</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="doctor" v-if="item.owner.userId == doctorInfo.uid"> -->
                <div class="doctor" v-if="item.owner.userId != userInfo.uid">
                  <div class="icon">
                    <div class="userIco">
                      <img :src="item.owner.userAvatar" alt="" />
                      <span>{{ item.owner.userName }}</span>
                    </div>
                  </div>
                  <div class="message_container">
                    <messageType :item="item"></messageType>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mescroll-vue>
        <div class="scrollBottom" v-if="showInput">
          <template>
            <div style="margin-left:10px">
              <el-row :gutter="20">
                <el-col :span="15">
                  <el-input v-model="input" size="medium" placeholder="请输入内容" style=""></el-input>
                </el-col>
                <el-col :span="4">
                  <el-button type="primary" @click="send" style="padding: 10px 25px;border-radius: 20px">发送</el-button>
                </el-col>
                <el-col :span="4">
                  <div class="buttons_item">
                    <el-button type="success" @click="upload" style="padding: 10px 15px;border-radius: 20px">上传图片</el-button>
                    <input type="file" class="input" @change="fileChange" v-show="false" ref="imgUploader" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <div v-if="status == 1" class="buttons_item" style="margin-top:15px;text-align:center;">
      <el-button type="danger" @click="stop" style="border-radius: 10px">断开连接</el-button>
    </div> -->
    <!-- <div class="handler">
      <h1>数据栏</h1>
      
      <h1>文档</h1>
      <div>
        <a href="dingtalk://dingtalkclient/action/sendmsg?dingtalk_id=pzobserve">有问题立刻联系我</a>
      </div>
    </div> -->

    <el-dialog
      title="导诊"
      width="mini"
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <template>
        <template>
          <div class="questionDataContent">
            {{ questionData.content }}
          </div>
        </template>
        <template v-if="questionData.questionType == `SINGLE_CHOICE`">
          <div class="SINGLE_CHOICE">
            <template v-for="i in questionData.answers">
              <el-button @click="selectSolution(i)">{{ i.answerText }}</el-button>
            </template>
          </div>
        </template>
        <template v-if="questionData.questionType == `MULTIPLE_CHOICE`">
          <div class="MULTIPLE_CHOICE">
            <template v-for="i in questionData.answers">
              <el-button :type="renderHight(i)" @click="addMULTIPLE_CHOICE_INPUT(i)">{{ i.answerText }}</el-button>
            </template>
            <div class="input" v-if="messageShow">
              <el-input v-model="otherMessage" placeholder="请输入内容"></el-input>
            </div>
            <div class="buttom">
              <el-button @click="answer(`${questionData.questionType}`)">提交</el-button>
            </div>
          </div>
        </template>
        <template v-if="questionData.questionType == `MULTIPLE_CHOICE_INPUT`">
          <div class="MULTIPLE_CHOICE_INPUT">
            <template v-for="i in questionData.answers">
              <el-button :type="renderHight(i)" @click="addMULTIPLE_CHOICE_INPUT(i)">{{ i.answerText }}</el-button>
            </template>
            <div class="input" v-if="messageShow">
              <el-input v-model="otherMessage" placeholder="请输入内容"></el-input>
            </div>
            <div class="buttom">
              <el-button @click="answer(`${questionData.questionType}`)">提交</el-button>
            </div>
          </div>
        </template>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import messageType from '../components/type/index';
import MescrollVue from 'mescroll.js/mescroll.vue';
import linkUp from './cDrugStore.js';
const { sdkConst } = linkUp;
export default {
  components: {
    messageType,
    MescrollVue
  },
  data() {
    return {
      messageShow: false,
      otherMessage: '',
      advisoryId: '',
      dialogVisible: false,
      showInput: true,
      input: '',
      loading: false,
      chatList: [],
      userInfo: null,
      doctorInfo: null,
      owner: {
        userId: null
      },
      group: {
        groupId: null
      },
      mescroll: null,
      mescrollDown: {
        auto: false,
        callback: this.downCallback,
        htmlNodata: ''
      },
      mescrollUp: {
        use: false
      },
      questionData: {
        id: null,
        content: '',
        answers: null,
        questionType: '',
        questionFromSource: ''
      },
      myAns: [],
      dataList: [], // 列表数据
      status : 0
    };
  },
  async mounted() {
    const { query } = this.$route;
    let uid = query.uid;
    let aid = query.aid;
    
    
    await linkUp.setEnv({
      env: sdkConst.env.H5,
      __linkUpRuntime: 'ONLINE'
    });
    // this.owner = {
    //   userId: query.userId
    // };
    this.owner = {
      userId: uid
    };
    this.advisoryId = aid;
    console.log(query);

    if (this.userId && this.advisoryId) {
      this.startConsult1();
    }
    // this.advisoryId = query.advisoryId;
    // console.log(query);

    // if (query.userId && query.advisoryId) {
    //   this.startConsult1();
    // }
  },
  methods: {
    renderHight(i) {
      const { myAns } = this;
      if (myAns.includes(i.answerCode)) return `primary`;
    },
    addMULTIPLE_CHOICE_INPUT(item) {
      console.log(item);
      const { answerCode } = item;
      const { myAns } = this;
      if (answerCode == `no`) {
        //独选
        this.myAns = [item.answerCode];
        this.messageShow = false;
      } else {
        this.myAns = myAns.map(v => {
          if (v != answerCode && v != `no`) {
            return v;
          }
        });
        if (answerCode == `other`) {
          this.messageShow = true;
        }
      }
      if (myAns.includes(answerCode)) {
        //反选
        if (answerCode == `other`) {
          this.messageShow = false;
        }
        this.myAns = myAns.map(v => {
          if (v != answerCode) {
            return v;
          }
        });
      } else {
        this.myAns.push(item.answerCode);
      }
    },
    async selectSolution(item) {
      this.myAns = [item];
      const params = {
        answers: this.myAns
      };
      await this.im.answerQs(params);

      this.dialogVisible = false;
    },
    async answer(flag) {
      const { myAns, questionData, otherMessage } = this;
      if (flag == `MULTIPLE_CHOICE_INPUT`) {
        const arr = JSON.parse(JSON.stringify(questionData.answers));
        let answers = arr.filter(v => {
          if (myAns.includes(v.answerCode)) {
            if (v.answerCode == `other`) {
              v.answerText = otherMessage;
            }
            return v;
          }
        });
        const params = {
          answers
        };

        await this.im.answerQs(params);
        this.dialogVisible = false;
      }
    },
    startConsult1() {
      if (!this.advisoryId) {
        alert('请输入咨询单ID');
        return;
      }
      this.im = linkUp.cDrugStore({
        owner: this.owner,
        appInfo: 'GGG',
        advisoryId: this.advisoryId
      });
      this.imInit();
    },
    imInit() {
      this.im.trpubsub.on('connecting', ms => {
        this.connecting();
      });
      this.im.trpubsub.on('LoginSuccess', ms => {
        this.LoginSuccess();
        this.status = 1;
        console.log(this.status)
      });
      this.im.trpubsub.on('close', () => {
        this.close();
      });

      this.im.trpubsub.on('message', ms => {
        this.message(ms);
      });

      this.im.trpubsub.on('changeGroup', e => {
        this.groupInit(e);
      });
      this.im.trpubsub.on('question', e => {
        this.question(e);
      });
      this.im.trpubsub.on('endQuestion', e => {
        this.endQuestion(e);
      });
    },
    endQuestion({ type, text }) {
      //答题中断
      console.warn(`答题结束`);
      console.log(text);
      this.status = 1;
      
    },
    question(q) {
      console.log(`收到导诊`);
      this.questionData = {
        id: q.questionCode,
        questionType: q.questionType,
        content: q.questionText,
        answers: q.answers,
        questionFromSource: q.questionFromSource
      };

      this.dialogVisible = true;
    },
    groupInit(e) {
      this.group = e.group;
      this.init();
    },
    changeGroup() {
      this.group = {
        groupId: this.targetGroupid
      };
      this.start();
    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    async downCallback() {
      const { mescroll } = this;
      // 联网请求
      let groupId = this.group.groupId;
      let res = await linkUp.api.getHistory({ ownerId: this.owner.userId, groupId, start: 0, limit: 100 });
      const { data } = res;
      this.chatList = data;
      this.$nextTick(() => {
        this.mescroll.endSuccess(data.length);
      });
      return res;
    },

    async fileChange() {
      if (!window.FormData) {
        //不支持formdata的不支持哦
        return;
      }
      let upload = this.$refs.imgUploader;
      const file = upload.files[0]; // File对象
      if (!file) {
        // 用户取消了本次操作
        return;
      }
      let { size } = file;

      let res = await linkUp.api.uploadImg(file);
      const { fullPath } = res.data.result;
      this.img = fullPath;
      this.sendImg();
    },

    upload() {
      //上传图片
      this.$refs.imgUploader.click();
    },
    stop() {
      this.im.closeCore(true);
    },
    start() {
      this.init();
    },
    async sendImg() {
      const clientMsgId = Date.now();
      const img = this.img;
      const message = {
        done: 1,
        owner: this.owner,
        group: this.group,
        clientMsgId,
        messageInfo: {
          type: 'Image',
          body: {
            thumbnail: img, // 缩略图url

            fileUrl: img, // 大图url
            size: 1024 // 图片大小
          }
        }
      };
      this.scrollBtm();
      this.chatList.push(message);
      let sendFlag = await this.im.sendMessage(message);
      if (sendFlag) {
        //发送成功
        this.chatList = this.chatList.map(v => {
          if (v.clientMsgId == clientMsgId) {
            return {
              ...message,
              done: 2
            };
          }
          return v;
        });
      } else {
        this.chatList = this.chatList.map(v => {
          if (v.clientMsgId == clientMsgId) {
            return {
              ...message,
              done: 3
            };
          }
          return v;
        });
      }
      this.input = '';
    },
    async resend(msg) {
      //重新发送消息
      this.scrollBtm();
      const clientMsgId = msg.clientMsgId;

      const message = {
        done: 1,
        owner: this.owner,
        group: this.group,
        clientMsgId,
        messageInfo: msg.messageInfo
      };

      let sendFlag = await this.im.sendMessage(message);
      if (sendFlag) {
        //发送成功
        this.chatList = this.chatList.map(v => {
          if (v.clientMsgId == clientMsgId) {
            return {
              ...message,
              done: 2
            };
          }
          return v;
        });
      } else {
        this.chatList = this.chatList.map(v => {
          if (v.clientMsgId == clientMsgId) {
            return {
              ...message,
              done: 3
            };
          }
          return v;
        });
      }
      this.input = '';
    },
    async send() {
      //发送消息
      this.scrollBtm();
      const clientMsgId = Date.now();

      const message = {
        done: 1,
        owner: this.owner,
        group: this.group,
        clientMsgId,
        messageInfo: {
          type: 'Text',
          body: {
            text: this.input
          }
        }
      };

      this.chatList.push(message);

      let sendFlag = await this.im.sendMessage(message);
      if (sendFlag) {
        //发送成功
        this.chatList = this.chatList.map(v => {
          if (v.clientMsgId == clientMsgId) {
            return {
              ...message,
              done: 2
            };
          }
          return v;
        });
      } else {
        this.chatList = this.chatList.map(v => {
          if (v.clientMsgId == clientMsgId) {
            return {
              ...message,
              done: 3
            };
          }
          return v;
        });
      }
      this.input = '';
    },
    scrollBtm() {
      this.$nextTick(() => {
        this.mescroll.scrollTo(999999, 0);
      });
    },
    async init() {
      this.loading = true;
      let r = await this.getGroupMessage(); //获取当前聊天组信息
      if (r.error) {
        this.loading = false;
        this.$message(r.error);
        return;
      }
      // let h = await this.getHistory(); //获取聊天历史记录
      let h = await this.getHistory(); //获取聊天历史记录
      // let h = await this.downCallback();
      if (h.error) {
        this.loading = false;
        this.$message(h.error);
        return;
      }
      this.loading = false;
    },
    async getGroupMessage() {
      let res = await linkUp.api.getGroupMessage({
        groupId: this.group.groupId,
        ownerId: this.owner.userId
      });
      try {
        if (res.error) {
          return res;
        }
      } catch (error) {}

      this.userInfo = res.user;
      this.doctorInfo = res.doctor;
      return res;
    },
    async getHistory() {
      const { mescroll } = this;
      // 联网请求
      let groupId = this.group.groupId;
      let res = await linkUp.api.getHistory({ ownerId: this.owner.userId, groupId, start: 0, limit: 10 });
      const { data } = res;
      this.chatList = data;
      this.$nextTick(() => {
        this.mescroll.endSuccess(data.length);
      });
      return res;
    },
    message(ms) {
      //需要再此根据serverMessageId来过滤重复的消息
      if (ms.group.groupId == this.group.groupId) {
        this.chatList.push(ms);
        this.scrollBtm();
      }
    },
    connecting() {
      this.$message('connecting');
    },
    LoginSuccess() {
      this.$message({
        message: 'LoginSuccess',
        type: 'success'
      });
      this.loading = false;
      this.status = 1;
    },
    close() {
      setTimeout(() => {
        if (this.im.getwsStatus() == 3) {
          this.$message.error('连接已断开');
          this.status = 0;
          this.$alert('您的连接已经断开，点击确认后立即重新连接', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              if (action == 'confirm') {
                this.startConsult1();
              }
            }
          });
        }
        //增加手动重连
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  padding-top: 30px;
  .buttons {
    position: absolute;
    right: -200px;
    &_item {
      margin-bottom: 5px;
    }
  }
  .questionDataContent {
    white-space: pre-wrap;
  }
  .m_box {
    height: 700px;
    width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 5px 5px #ccc;
    position: relative;
    background: RGB(245,245,245);
    .scrollBox {
      height: 100%;
      display: flex;
      flex-direction: column;
      .chatScroll {
        flex: 1;
        // overflow: scroll;
      }
    }
    .chatItem {
      padding: 20px 12px;
      .message_container {
          display: inline-block;
          background: white;
          max-width: 80%;
          font-size: 14px;
          padding: 8px 10px;
          position: relative;
          border-radius: 4px;
        }
      .doctor {
        .icon {
          padding-left: 20px;
        }
        .userIco {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          span{
              position: relative;
              top: -20px;
              left: 8px;
              color: grey;
              font-size: 13px;
            }
        }
        .message_container {
          
          margin-left: 69px;
          
          top: -17px
        }
      }
      .user {
        .icon {
          overflow: hidden;
          padding-right: 20px;
          .name {
            float: right;
          }
          .userIco {
            float: right;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
            
          }
        }
        .message_container {
          text-align: right;
          float: right;
          right: 70px;
          top: -42px;
          background: #95EC69;
        }
      }
    }
  }
  .chatscroller {
    // height: 100%;
    height: 500px;
    width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border: 3px solid #ccc;
    position: relative;

    .chatbox {
      flex: 1;
      overflow: hidden;
      .chatList {
        max-height: 100%;
        overflow-y: scroll;
      }
      .message_container {
        background: #f8f8f8;
        border-radius: 8px;
        padding: 12px 0;
      }
      .chatItem {
        padding: 20px 12px;
        .message_container {
          padding-left: 20px;
          padding-right: 20px;
        }
        .doctor {
          .icon {
            padding-left: 20px;
          }
          .userIco {
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
        }
        .user {
          .icon {
            overflow: hidden;
            padding-right: 20px;
            .name {
              float: right;
            }
            .userIco {
              float: right;
              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
            }
          }
          .message_container {
            text-align: right;
          }
        }
      }
    }
  }

  .mescroll_box {
    display: flex;
    overflow: hidden;
    height: 100%;
    flex-direction: column;
    .tip_box{
      width: 100%;
      padding: 20px 100px;
      box-sizing: border-box;
      background: white;
      height: 700px;
      position: absolute;
      // top: 100px;
      z-index: 1;
      opacity: 1;
    }
    
    .mescroll {
      flex: 1;
    }
    .scrollBottom {
      height: 55px;
      border-top: 1px solid #eee;
      padding-top: 15px;
      background: white;
    }
  }
}
</style>
