/*
 * @Author: pz
 * @Date: 2020-05-25 11:50:52
 * @Last Modified by: pz
 * @Last Modified time: 2020-07-16 18:09:17
 */

import DrugStore from '@/drugstore/main';
import pubsub from '@/utils/PubSub';
import { GenProto, debugMsg } from '@/utils/index';
import { api } from '@/api/api';
import constant from '@/utils/constant';
const { getAdvisoryInfo, getQuestion, answerQuestion } = api;

/**
 *
 * @description 只写处方C端逻辑
 * @class cDrugStore
 */
class cDrugStore {
  constructor(params) {
    this.drugstore = null;
    this.userInitMessage = params;
    this.advisoryId = params.advisoryId;
    this.trpubsub = new pubsub();
    this.core = null;
    this.advisoryStatus = 'UNSTART'; //未开始
    this.fromSource = ``; //
    this.questionMessage = null;
    this.findDoctorConfig = null;
    this.findDoctorTimes = 1;
    this.noNotifyClose = false;
    this.findDorctorError = null;
    this.init();
  }
  endQuestion({ type, text }) {
    this.trpubsub.emit('endQuestion', { type, text });
  }
  findDoctorError() {
    this.trpubsub.emit('findDoctorError', this.findDorctorError);
  }
  async initConsult() {
    /*  START,
    REJECT,
    EXPIRE,
    CANCEL,
    END;
    FIND_DOCTOR找到医生 */
    // 拿到咨询单信息
    const ConsultInfoRes = await getAdvisoryInfo({
      advisoryId: this.advisoryId,
      fromSource: 'ADVISORY'
    });
    if (!ConsultInfoRes.ok) {
      this.endQuestion({
        type: 'apiError',
        text: ConsultInfoRes.error.message
      });
      return;
    }
    const { result: ConsultInfo } = ConsultInfoRes;

    this.advisoryStatus = ConsultInfo.advisoryStatus;
    this.fromSource = ConsultInfo.fromSource;
    if (this.advisoryStatus == `FIND_DOCTOR`) {
      this.changeDoctor();
      return;
    }
    if (this.advisoryStatus == `CANCEL`) {
      this.trpubsub.emit('changeGroup', {
        ...ConsultInfo,
        group: {
          groupId: ConsultInfo.groupId
        }
      });
      this.endQuestion({
        type: 'advisoryEnd',
        text: '当前导诊单已取消'
      });
    }
    if (this.advisoryStatus == 'REJECT') {
      this.trpubsub.emit('changeGroup', {
        ...ConsultInfo,
        group: {
          groupId: ConsultInfo.groupId
        }
      });
      this.endQuestion({
        type: 'advisoryEnd',
        text: '咨询单被拒绝'
      });
    }
    if (this.advisoryStatus == 'EXPIRE') {
      this.trpubsub.emit('changeGroup', {
        ...ConsultInfo,
        group: {
          groupId: ConsultInfo.groupId
        }
      });
      this.endQuestion({
        type: 'advisoryEnd',
        text: '咨询单超时'
      });
    }
    if (this.advisoryStatus == 'START') {
      this.trpubsub.emit('changeGroup', {
        ...ConsultInfo,
        group: {
          groupId: ConsultInfo.groupId
        }
      });
      const q = await this.getQuestion();
      if (!q.ok) {
        this.endQuestion({
          type: 'apiError',
          text: q.error.message
        });
      }
      const { result: question } = q;
      return this.startQuestions(question);
    }
    if (this.advisoryStatus == 'END') {
      this.trpubsub.emit('changeGroup', {
        ...ConsultInfo,
        group: {
          groupId: ConsultInfo.groupId
        }
      });
      this.changeDoctor();
    }
  }
  async getQuestion(params) {
    if (params) {
      const q = await getQuestion(params);
      return q;
    }
    const q = await getQuestion({
      advisoryId: this.advisoryId,
      questionFromSource: this.fromSource
    });
    return q;
  }
  async answerQs(params) {
    const anserParams = {
      ...params,
      questionCode: this.questionMessage.questionCode,
      advisoryId: this.advisoryId,
      questionFromSource: this.questionMessage.questionFromSource
    };
    const res = await answerQuestion(anserParams);
    if (!res.ok) {
      this.endQuestion({
        type: 'apiError',
        text: res.error.message
      });
      return false;
    }
    if (this.fromSource == `PRESCRIPTION`) {
      if (res.isEndQuestion) {
        return this.endQuestion({
          type: 'advisoryFinish',
          text: '当前导诊已完成'
        });
      }
    }
    const { result: q } = res;
    setTimeout(() => {
      this.startQuestions(q);
    }, 0);
    return true;
  }
  startQuestions(q) {
    this.questionMessage = q;
    const { fromSource } = this;
    if (fromSource == `ADVISORY`) {
      if (q.advisoryStatus === `END`) {
        if (q.isEndQuestion) return this.changeDoctor();
      }
      if (q.advisoryStatus != `REJECT` && q.advisoryStatus != `EXPIRE` && q.advisoryStatus != `CANCEL`) {
        return this.trpubsub.emit('question', q);
      }
      if (q.advisoryStatus == `REJECT`) {
        this.endQuestion({
          type: 'advisoryEnd',
          text: '当前导诊单被拒绝'
        });
      }
      if (q.advisoryStatus == `EXPIRE`) {
        this.endQuestion({
          type: 'advisoryEnd',
          text: '当前导诊单已超时'
        });
      }
      if (q.advisoryStatus == `CANCEL`) {
        this.endQuestion({
          type: 'advisoryEnd',
          text: '当前导诊单已取消'
        });
      }
    }
    if (fromSource == 'PRESCRIPTION') {
      if (q.isEndQuestion) {
        return this.endQuestion({
          type: 'advisoryFinish',
          text: '当前导诊已完成'
        });
      }
      return this.trpubsub.emit('question', q);
    }
  }
  async changeDoctor() {
    const timer = this.findDoctorConfig.timer * 1000 || 10000;
    const times = this.findDoctorConfig.times || 10;
    if (this.findDoctorTimes >= times) {
      //查找医生超出配置中心指定次数
      clearTimeout(this.findDoctorConfig.timeout);
      this.findDoctorError();
      return;
    }
    this.findDoctorConfig.timeout = setTimeout(() => {
      this.findDoctorTimes += 1;
      this.changeDoctor();
    }, timer);

    this.fromSource = `PRESCRIPTION`;
    const res = await getAdvisoryInfo({
      advisoryId: this.advisoryId,
      fromSource: 'PRESCRIPTION'
    });

    const { result: ConsultInfo, ok, error } = res;
    if (ok) {
      clearTimeout(this.findDoctorConfig.timeout);
      this.trpubsub.emit('changeGroup', {
        ...ConsultInfo,
        group: {
          groupId: ConsultInfo.groupId
        }
      });
      const q = await this.getQuestion({
        advisoryId: this.advisoryId,
        questionFromSource: 'PRESCRIPTION'
      });
      const { result: question } = q;
      setTimeout(() => {
        this.startQuestions(question);
      }, 0);
      return;
    }
    if (!ok) {
      if (error.code != `TIMEOUT` && error.code != `-99`) {
        // 查找医生发生异常t
        clearTimeout(this.findDoctorConfig.timeout);
        this.endQuestion({
          type: 'advisoryEnd',
          text: error.message
        });
      }
      this.findDorctorError = res;
    }
  }
  init() {
    const configAll = constant.configAll;
    debugMsg(configAll);
    const { ws, timer, times } = configAll;
    this.findDoctorConfig = {
      timer,
      times
    };
    const params = this.userInitMessage;
    const proto = GenProto('ws');
    let url = `${proto}${ws}`;
    if (ws == `//third.y.91jkys.com/api/chat/2.0/imws_open`) {
      url = `wss:${ws}`;
    }

    this.drugstore = new DrugStore({
      ...params,
      url,
      heartBeatParams: {
        type: 'LoginKeep',
        version: '2.0',
        ownerId: params.owner.userId
      },
      heartDistance: 20000
    });
    this.Dspubsub = this.drugstore.Dspubsub;
    this.core = this.drugstore.core;
    this.delayImmit();
  }
  delayImmit() {
    //延迟注入事件
    this.onOpen();
    this.onclose(); //监听close
    this.message();
    setTimeout(() => {
      this.connecting();
    }, 0);
  }
  getwsStatus() {
    return this.drugstore.core.readyState;
  }
  onOpen() {
    const params = this.userInitMessage;
    let ownerId = params.owner.userId;
    const loginStr = JSON.stringify({
      ownerId,
      type: 'Login',
      version: '2.0'
    });
    this.core.pubsub.on('wsopen', () => {
      //发送登录请求 登录成功后才可以正常发送消息
      this.core.send(loginStr);
    });
    this.core.pubsub.on('message', (ev) => {
      const message = JSON.parse(ev.data); //消息本体 json需要转成对象后才可以继续操作
      const { type } = message;
      if (type == 'LoginSuccess') {
        //登录成功
        this.initConsult();
      }
      if (type == 'LoginOut') {
        //处理被踢出的情况
        this.closeCore();
      }
    });
  }
  closeCore(bl) {
    //关闭socket
    if (bl) {
      this.noNotifyClose = bl;
    }
    this.core.closeWs();
  }

  onclose() {
    this.core.pubsub.on('wsclose', () => {
      if (this.noNotifyClose) {
        return;
      }
      console.log(this.getwsStatus(), 'getwsStatusgetwsStatusgetwsStatus');
      this.trpubsub.emit('close');
    });
  }
  sendAck(params) {
    //发送ACK给服务端
    const ackParams = {
      ownerId: params.owner.userId,
      serverMsgId: params.serverMsgId,
      type: 'ACK',
      version: '2.0'
    };

    this.core.send(JSON.stringify(ackParams));
  }
  async sendMessage(message) {
    const { owner, appInfo } = this.userInitMessage;
    const { group, messageInfo, clientMsgId } = message;

    return await this.drugstore.sendMessage({
      ...messageInfo,
      clientMsgId,
      group: group,
      owner,
      appInfo
    });
  }
  message() {
    this.Dspubsub.on('message', (message) => {
      //后台消息格式和前端发出去时不匹配 优化过后再发给业务
      const formatMessage = {
        clientMsgId: message.clientMsgId,
        serverMsgId: message.serverMsgId,
        createDate: message.createDate,
        group: message.group,
        messageInfo: {
          body: message.body,
          type: message.type
        },
        owner: message.owner
      };

      if (message.serverMsgId) {
        this.sendAck({
          serverMsgId: message.serverMsgId,
          owner: this.userInitMessage.owner
        });
      }
      this.trpubsub.emit('message', formatMessage);
    });
  }
  connecting() {
    this.trpubsub.emit('connecting');
  }
  getwsStatus() {
    //获取ws状态
    return this.core.readyState;
  }
}

export default cDrugStore;
