import constant from '@/utils/constant';
export const cutUrlHeader = (url) => {
  //发送图片消息时将绝对地址转为相对地址
  const cutHeader = [
    'http://static.qa.91jkys.com',
    'https://static.qa.91jkys.com',
    'http://static.pre.91jkys.com',
    'https://static.pre.91jkys.com',
    'http://static.91jkys.com',
    'https://static.91jkys.com',
    'http://static-minses.91jkys.com',
    'https://static-minses.91jkys.com'
  ];
  let u = url;
  cutHeader.map((v) => {
    u = u.replace(v, '');
  });
  return u;
};

export const GenProto = (str) => {
  if (constant.env == 1) {
    //H5
    const ishttps = 'https:' == document.location.protocol ? true : false;
    if (str === 'ws') {
      //长连接
      if (ishttps) return `wss:`;
      return `ws:`;
    }
    if (ishttps) return `https:`;
    return `http:`;
  }

  if (constant.env == 2) {
    // 小程序
    if (str === `ws`) {
      return `wss:`;
    }
    return `https:`;
  }
};

export const genSDKInfo = (version, name) => {
  //增加获取当前SDK信息的API 便于找bug
  const sdkVersion = version;
  let ua = `当前非浏览器环境`;
  if (navigator) {
    ua = navigator.userAgent;
  }
  const packageName = name;
  return {
    sdkVersion,
    constant,
    ua,
    packageName
  };
};
export const debugMsg = (message) => {
  const { configAll } = constant;
  if (configAll.debug) {
    console.log(message);
  }
};
