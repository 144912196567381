<template>
  <div class="imgbox">
    <el-image style="width: 200px; height: 200px" :src="msg.body.fileUrl || msg.body.thumbnail" @click="cg"></el-image>
    <el-dialog :visible.sync="dialogVisible">
      <img
        style="margin:auto;display:block"
        width="100%"
        :src="msg.body.url || msg.body.fileUrl || msg.body.fileUrl"
        alt=""
      />
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ['msg'],
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    cg() {
      this.dialogVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.imgbox {
  img {
    width: 200px;
    height: 200px;
  }
}
</style>
