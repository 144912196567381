/*
 * @Author: pz
 * @Date: 2019-12-04 11:29:29
 * @Last Modified by: pz
 * @Last Modified time: 2020-08-04 18:43:31
 */

import PubSub from '@/utils/PubSub';
import constant from '@/utils/constant';
class Core {
  constructor(conParams) {
    this.InitParams = conParams; //从上一层接到的参数 不关注是什么内容
    this.pubsub = new PubSub();
    this.onConnecting();
    this.url = ''; //连接url
    this.heartBeatTimer = null; //心跳定时器
    this.heartBeatParams = null; //心跳参数
    this.heartDistance = null;
    this.__ws__ = null; //websocket本尊
    this.settings = {
      automaticOpen: false, //自动重连设置
      reconnectDecay: 0 //重连延迟
    };
    this.readyState = 0; //imCore 状态
    this.LoginKeepTimer = null;
    this.baseInit();
  }
  detectionLoginKeep() {
    //检测是否掉线
    this.LoginKeepTimer = setTimeout(() => {
      //两分钟没有收到LoginKeep 认为掉线尝试重连
      this.reConnect();
    }, 40000);
  }
  async baseInit() {
    const conParams = this.InitParams;
    //基本初始化
    this.url = conParams.url;
    this.heartBeatParams = conParams.heartBeatParams;
    this.heartDistance = conParams.heartDistance || 10000; //默认心跳频率2000ms
    let __ws__ = await this.connect();

    if (constant.env == 1) {
      this.__ws__.onmessage = (ev) => {
        this.onMessage(ev);
      }; //收到系统消息
      this.__ws__.onerror = (ev) => {
        this.onError(ev);
      };
      this.__ws__.onclose = (ev) => {
        this.onClose(ev);
      };
    } else if (constant.env == 2) {
      //兼容微信小程序
      wx.onSocketMessage((ev) => {
        this.onMessage(ev);
      });
      wx.onSocketError((ev) => {
        this.onError(ev);
      });
      wx.onSocketClose((ev) => {
        this.onClose(ev);
      });
    }
  }

  async connect() {
    //开始连接
    return new Promise((res, rej) => {
      if (constant.env == 1) {
        //websocket直连
        this.__ws__ = new WebSocket(this.url);
        this.readyState = this.__ws__.readyState;
        this.__ws__.onopen = () => {
          this.readyState = this.__ws__.readyState;
          this.onOpen();
          this.heartBeat();
          res(this.__ws__);
        };
      } else if (constant.env == 2) {
        //兼容微信小程序
        console.log(this.url);
        console.log('小程序开始连接');

        wx.connectSocket({
          url: this.url
        }); //连接IM
        console.log('小程序连接代码后');
        this.__ws__ = wx;
        wx.onSocketOpen(() => {
          this.readyState = 1;
          console.log('小程序连接成功');
          this.onOpen();
          this.heartBeat();
          res(this.__ws__);
        });
      }
    });
  }
  reConnect() {
    clearTimeout(this.LoginKeepTimer);
    this.closeWs();
    /*   //重连即重新初始化
    this.delConfig();
    this.onConnecting();
    this.baseInit(); */
  }
  delConfig() {
    //删除无关属性
    clearTimeout(this.heartBeatTimer);
  }
  send(message) {
    //发送消息
    if (constant.env == 1) {
      this.__ws__.send(message);
    } else if (constant.env == 2) {
      wx.sendSocketMessage({
        data: message
      });
    }
  }
  onMessage(evt) {
    //监听消息
    const message = JSON.parse(evt.data); //消息本体
    const { type } = message;
    if (type == 'LoginKeep') {
      clearTimeout(this.LoginKeepTimer);
      this.detectionLoginKeep();
    }
    this.pubsub.emit('message', evt); //通过观察者模式分发消息本身不对消息进行任何处理
  }
  closeWs() {
    if (constant.env == 1) {
      this.__ws__.close();
    } else if (constant.env == 2) {
      wx.closeSocket();
    }
    clearTimeout(this.LoginKeepTimer);
  }
  onClose() {
    //关闭
    console.log('onclose');
    this.pubsub.emit('wsclose');
    this.readyState = this.__ws__.readyState || 3;
    clearTimeout(this.heartBeatTimer);
    clearTimeout(this.LoginKeepTimer);
  }
  heartBeat() {
    //心跳参数由外层获取
    const params = JSON.stringify(this.heartBeatParams);
    this.send(params);
    this.heartBeatTimer = setTimeout(() => {
      this.heartBeat();
    }, this.heartDistance);
  }
  onConnecting() {
    //连接中callback
    // this.pubsub.emit('wsConnecting');
  }
  onError(ev) {
    this.readyState = this.__ws__.readyState;
  }
  onOpen() {
    //连接成功callback
    this.detectionLoginKeep(); //开始检测
    this.pubsub.emit('wsopen');
  }
}

export default Core;
