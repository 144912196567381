export default {
  QA: {
    get_advisory_info: 'api/drugstore/1.0/get_advisory_info',
    get_question: 'api/drugstore/1.0/get_question',
    answer_question: 'api/drugstore/1.0/answer_question',
    http: '//minses.apigw.qa.91jkys.com/',
    ws: '//minses.apigw.qa.91jkys.com/api/chat/2.0/imws',
    imGroupInfo: 'api/chat/1.0/im_group_info/',
    imHistoryMessage: 'api/chat/3.0/im_history_message/',
    uploadImg: 'api/third-upload/1.0/simple-file-stream',
    debug: false
  },
  TR: {
    get_advisory_info: 'api/drugstore/1.0/get_advisory_info',
    get_question: 'api/drugstore/1.0/get_question',
    answer_question: 'api/drugstore/1.0/answer_question',
    http: '//third.y.91jkys.com/',
    ws: '//third.y.91jkys.com/api/chat/2.0/imws_open',
    imGroupInfo: 'api/chat/1.0/im_group_info/',
    imHistoryMessage: 'api/chat/3.0/im_history_message/',
    uploadImg: 'api/third-upload/1.0/simple-file-stream'
  },
  PRE: {
    get_advisory_info: 'api/drugstore/1.0/get_advisory_info',
    get_question: 'api/drugstore/1.0/get_question',
    answer_question: 'api/drugstore/1.0/answer_question',
    http: '//minses.apigw.pre.91jkys.com/',
    ws: '//minses.apigw.qa.91jkys.com/api/chat/2.0/imws_open',
    imGroupInfo: 'api/chat/1.0/im_group_info/',
    imHistoryMessage: 'api/chat/3.0/im_history_message/',
    uploadImg: 'api/third-upload/1.0/simple-file-stream'
  },
  ONLINE: {
    get_advisory_info: 'api/drugstore/1.0/get_advisory_info',
    get_question: 'api/drugstore/1.0/get_question',
    answer_question: 'api/drugstore/1.0/answer_question',
    http: '//minses.apigw.91jkys.com/',
    ws: '//minses.apigw.91jkys.com/api/chat/2.0/imws_open',
    imGroupInfo: 'api/chat/1.0/im_group_info/',
    imHistoryMessage: 'api/chat/3.0/im_history_message/',
    uploadImg: 'api/third-upload/1.0/simple-file-stream'
  }
};
