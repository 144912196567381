const constant = {
  runtimeEnv: {
    h5: 1, //h5
    weApp: 2 //微信小程序
  },
  env: 1,
  __linkUpRuntime: null, //运行环境 QA
  configJson: {
    cDrugStore: {
      QA: 'https://static.91jkys.com/dms/d2787678c9de6d927e80f379df397c88.json',
      TR: 'https://static.91jkys.com/dms/443c5ed56dea9e7c06f4d30780d6a68a.json',
      PRE: 'https://static.91jkys.com/dms/720959a79018e6e0e0e047190745e6c9.json',
      ONLINE: 'https://static.91jkys.com/dms/b07e25e5a2a6a9e3858e7dcf73bf06f6.json'
    },
    tr: {
      QA: 'https://static.91jkys.com/dms/7a4ebef9ab0dc74cda8eeae14878d598.json',
      TR: 'https://static.91jkys.com/dms/4d8c0eebaab978a087e6a370b34442e0.json',
      PRE: 'https://static.91jkys.com/dms/7c672be52fa37edb6122d9bcfeafe460.json',
      ONLINE: 'https://static.91jkys.com/dms/498dd951368ba5816b66088af9e9928d.json'
    },
    drugPlatform: {
      QA: 'https://static.91jkys.com/dms/88d58441682568a9170dbcf63cc3224a.json',
      TR: 'https://static.91jkys.com/dms/7c9f8b54474698e56778926431f75d0b.json',
      PRE: 'https://static.91jkys.com/dms/4d82a22de9ba1211f1852dd0a7a1ac79.json',
      ONLINE: 'https://static.91jkys.com/dms/b486d149d839fcc5c0b08a3173b67409.json'
    },
    toCustomer: {
      QA: 'https://static.91jkys.com/dms/f1fc49391c614b1e6bdd4f390a7644ac.json',
      TR: 'https://static.91jkys.com/dms/7f2da75f48a278f1e6885b3c4bc760a7.json',
      PRE: 'https://static.91jkys.com/dms/50ad5c76434ba27bdc742a81fbfbe6f3.json',
      ONLINE: 'https://static.91jkys.com/dms/7042087eedb270d6def12cccf5589d5f.json'
    },
    drugstoreDoctor: {
      QA: 'https://static.91jkys.com/dms/3372922647297c432183d42e7cf5d2d1.json',
      TR: 'https://static.91jkys.com/dms/852bc5330924011624ebad6ebb3e987f.json',
      PRE: 'https://static.91jkys.com/dms/7b5773d655b5674d1dd39da9a0277af2.json',
      ONLINE: 'https://static.91jkys.com/dms/fb24be5fdf8c1e2b3f20beca1eab1985.json'
    },
    serviceIm: {
      QA: 'https://static.91jkys.com/dms/c47a9119e702d9ad4811416612778f9a.json',
      TR: 'https://static.91jkys.com/dms/4af7dfa9c21e45a48ae19e722eb11d1b.json',
      PRE: 'https://static.91jkys.com/dms/f25b7aa881c5dfa1bee5c9c348a79694.json',
      ONLINE: 'https://static.91jkys.com/dms/6d2d378c42d9d60542ec2b5975914c18.json'
    }
  },
  sdkVersionString: null, //当前运行的SDK 适配版本
  configAll: {}
};
export default constant;
