<template>
  <div>{{ msg.body.text }}</div>
</template>
<script>
export default {
  props: ['msg']
};
</script>
<style lang="scss" scoped>
div {
  white-space: pre-wrap;
  // overflow: scroll;
}
</style>
