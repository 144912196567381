import httpInstance from '@/api/index';
import { debugMsg } from '@/utils/index';
import constant from '@/utils/constant';
import axios from 'axios';
import ToMinsesImCoreConfig from '@/api/ToMinsesImCoreConfig/conf.js';
export const api = {
  async getZhiyunConfig(conf) {
    console.log(`开始获取配置文件`);
    const runtime = constant.__linkUpRuntime; //qa  tr pub online
    const sdkVersionString = constant.sdkVersionString;
    console.log(`当前SDK版本为：${sdkVersionString}，当前SDK执行环境为：${runtime}`);
    // 判断是否需要离线的配置文件

    if (sdkVersionString != `toMinses`) {
      const jsonStr = constant.configJson[sdkVersionString][runtime];
      console.log(`配置文件获取成功${jsonStr}`);
      let { data } = await axios.get(jsonStr);
      console.log('配置文件加载完毕');
      debugMsg(data);
      debugMsg(constant);
      constant.configAll = data;
      return data;
    }
    if (sdkVersionString == `toMinses`) {
      console.log('配置文件加载完毕');
      debugMsg(conf);
      debugMsg(constant);
      const data = await new Promise((resolve, reject) => {
        resolve(conf);
      });
      constant.configAll = data;
      return data;
    }
  },
  async uploadImg(file) {
    // const url = ceateBaseURL();
    const { configAll } = constant;
    const { uploadImg } = configAll;
    if (constant.env == constant.runtimeEnv.weApp) {
      // return Promise.reject('小程序不支持上传');
      //实现小程序中图片上传  抹平底层兼容问题 无法上传 有问题
      return new Promise((resolve, rej) => {
        wx.uploadFile({
          // url: `api/third-upload/1.0/simple-file-stream`,
          url: uploadImg,
          filePath: file[0],
          name: 'file',
          formData: {
            projectId: 'GuoYao',
            secret: '0907438abefb60386901ebec6eed8c27'
          },
          success: function (res) {
            const { data } = res;
            resolve(JSON.parse(data));
          },
          fail: function (e) {
            wx.showModal({
              title: '提示',
              content: '上传失败',
              showCancel: false
            });
            rej(e);
          },
          complete: function () {}
        });
      });
    }
    if (constant.env == constant.runtimeEnv.h5) {
      // h5平台利用formData上传图片
      let fd = new FormData();
      fd.append('file', file);
      fd.append('projectId', 'GuoYao');
      fd.append('secret', '0907438abefb60386901ebec6eed8c27');
      return httpInstance({
        method: 'POST',
        url: uploadImg,
        // url: `api/third-upload/1.0/simple-file-stream`,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: fd
      });
    }
  },
  async getHistory({ ownerId, groupId, start, limit, startServerMsgId }) {
    //获取聊天历史
    // const url = ceateBaseURL();
    const { configAll } = constant;
    const { imHistoryMessage } = configAll;
    let res = await httpInstance.post(
      // `api/chat/3.0/im_history_message/`,
      imHistoryMessage,
      {
        groupId,
        ownerId,
        start,
        limit,
        startServerMsgId
      }
    );
    if (res.data.ok === false) {
      return {
        error: res.data.error.message
      };
    }
    const data = res.data.result.map((v) => {
      return {
        // serverMessageId: v.serverMessageId,
        clientMsgId: v.clientMsgId,
        createDate: v.createDate,
        serverMsgId: v.serverMsgId,
        sentStatus: v.sentStatus, //表示消息发送状态
        // serverMsgId: v.serverMessageId,
        group: v.group,
        owner: v.owner,
        messageInfo: {
          type: v.type,
          body: v.body
        }
      };
    });
    /* const sortData = data.sort ((a, b) => {
      //按时间倒序排序一下
      return a.createDate - b.createDate;
    }); */
    const sortData = data.reverse();
    return {
      data: sortData
    };
    /*  return {
      data: [
        {
          serverMessageId: 1566474238400,
          clientMsgId: 1566474238400,
          createDate: 1566474238400,
          group: {
            groupId: 94881,
            type: 1,
            avatar: 'http://static.qa.91jkys.com/index/478d5744-81c2-4cca-aa10-e4db1298d077.png',
            title: '测试',
            subTitle: '点点滴滴',
            date: 1566474238400
          },
          owner: {
            userId: 98215506,
            userName: '所属',
            userAvatar: 'http://static.qa.91jkys.com/index/478d5744-81c2-4cca-aa10-e4db1298d077.png'
          },
          messageInfo: {
            type: 'Text',
            body: {
              text: 'ZZZZZ'
            }
          }
        }
      ]
    }; */
  },
  async getGroupMessage({ groupId, ownerId }) {
    //获取聊天组信息
    // const url = ceateBaseURL();
    let res = null;
    const { configAll } = constant;
    const { imGroupInfo } = configAll;
    res = await httpInstance.post(
      //`api/chat/1.0/im_group_info/`,
      imGroupInfo,
      {
        imGroupId: groupId,
        ownerId
      }
    );
    if (res.data.ok === false) {
      return {
        error: res.data.error.message
      };
    }

    /* let res = await httpInstance.post (
      `http://mock.qa.91jkys.com/api/project/mock/54/api/chat/1.0/im_group_info`,
      {
        imGroupId: groupId,
        ownerId,
      }
    ); */
    const { data } = res;
    return {
      doctor: {
        ...data.result.doctor,
        avatar: `https://static.91jkys.com/third_files/GuoYao/202004/01/e54aab8c669049c99822e4951fc0a6c3.png`
      },
      user: data.result.user
    };
    /*  return {
      doctor: {
        modified: 1560416726000,
        nickName: '杨振环医生',
        ownerName: '杨振环医生',
        uType: 1,
        uid: 86314344,
        avatar: 'https://tse3-mm.cn.bing.net/th?id=OIP.O0HBY0ZVl_VgVhViD8SvdQHaFH&w=300&h=207&c=7&o=5&pid=1.7'
      },
      user: {
        avatar:
          'https://tse1-mm.cn.bing.net/th?id=OET.1954d83b3b4347f09bdad5d2fdd3ac3b&w=135&h=272&c=7&rs=1&o=5&pid=1.9',
        nickName: '董蔚来',
        ownerName: '董蔚来',
        uType: 2,
        uid: 98215506
      }
    }; */
  },
  async getAdvisoryInfo(data) {
    const { configAll } = constant;
    const { get_advisory_info } = configAll;
    const r = await httpInstance.post(
      // `http://mock.qa.91jkys.com/api/project/mock/32/get_advisory_info`,
      // `api/drugstore/1.0/get_advisory_info`,
      get_advisory_info,
      data
    );
    return r.data;
  },

  async getQuestion(data) {
    const { configAll } = constant;
    const { get_question } = configAll;
    const r = await httpInstance.post(
      // `api/drugstore/1.0/get_question`,
      get_question,
      // `http://mock.qa.91jkys.com/api/project/mock/32/get_question`,
      data
    );
    return r.data;
  },
  async answerQuestion(data) {
    const { configAll } = constant;
    const { answer_question } = configAll;
    const r = await httpInstance.post(
      answer_question,
      // `api/drugstore/1.0/answer_question`,
      // `http://mock.qa.91jkys.com/api/project/mock/32/answer_question`,
      data
    );
    return r.data;
  }
};
