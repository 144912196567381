/*
 * @Author: pz
 * @Date: 2020-03-27 17:06:24
 * @Last Modified by: pz
 * @Last Modified time: 2020-04-28 17:52:42
 */

import { version, name } from '../../package.json';
// 给三方使用的常量 im参数尽量不暴露实际内容便于更改
let ua = `当前非浏览器环境`;
if (navigator) {
  ua = navigator.userAgent;
}
export default {
  env: {
    H5: 1,
    MINIAPP: 2
  },
  clientEnv: {
    //客户端信息
    sdkVersion: version,
    ua,
    packageName: name
  }
};
