<template>
  <div>
    <div v-if="item.messageInfo.type == 'Text'">
      <msg-text :msg="item.messageInfo"></msg-text>
    </div>
    <div v-if="item.messageInfo.type == 'Image'">
      <msg-image :msg="item.messageInfo"></msg-image>
    </div>
    <div v-if="item.messageInfo.type == 'RichLink'">
      <msg-richlink :msg="item.messageInfo"></msg-richlink>
    </div>
    <div v-if="item.messageInfo.type == 'Finish'">
      {{ item.messageInfo.body.text }}
    </div>
    <div v-if="item.messageInfo.type == 'SysText'">
      {{ item.messageInfo.body.text }}
    </div>
    <div v-if="item.messageInfo.type == 'Video'">
      问诊视频已生成
    </div>
  </div>
</template>
<script>
import msgText from './text';
import msgImage from './image';
import msgRichlink from './msgRichlink';
export default {
  components: { msgText, msgImage, msgRichlink },
  props: ['item'],
  mounted() {},
  methods: {
    openVideo() {
      const el = document.createElement('video');
      el.src = this.item.messageInfo.body.text.replace('.MP4', '.mp4');
      el.play();
      console.log(this.item.messageInfo.body.text);
    }
  }
};
</script>
