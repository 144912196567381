import axios from 'axios';
import constant from '@/utils/constant';
import { GenProto } from '@/utils/index';
import { debugMsg } from '@/utils/index';
const ceateBaseURL = function () {
  const proto = GenProto();
  const configAll = constant.configAll;

  const { http } = configAll;
  if (http == `//third.y.91jkys.com/`) {
    return `https:${http}`;
  }
  return `${proto}${http}`;
};
const instance = axios.create({
  timeout: 60000,
  contentType: 'application/x-www-form-urlencoded',
  withCredentials: true
});
instance.interceptors.request.use(
  function (config) {
    if (config.url.includes('http') || config.url.includes('https')) {
    } else {
      config.url = ceateBaseURL() + config.url;
    }

    debugMsg(config);
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
export default instance;
