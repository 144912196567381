/*
 * @Author: pz
 * @Date: 2019-12-04 15:14:35
 * @Last Modified by: pz
 * @Last Modified time: 2020-08-10 13:58:53
 */

import core from '@/core/main';

import pubsub from '@/utils/PubSub';
import * as utils from '@/utils/index';

/**
 * @description 只写处方平台共有逻辑
 * 不单独处理三方逻辑
 * 不判断环境
 *
 * @class DrugStore
 */
class DrugStore {
  constructor(params) {
    this.core = null;
    this.sendMessageParams = params;
    this.Dspubsub = new pubsub();
    this.init(params);
  }
  init(params) {
    const { url, heartBeatParams, heartDistance } = params;
    this.core = new core({
      url,
      heartBeatParams,
      heartDistance
    });
    this.core.pubsub.on('message', (ev) => {
      const message = JSON.parse(ev.data); //消息本体
      const { type } = message;

      if (type == 'Text') {
        this.Dspubsub.emit('message', message);
      }
      if (type == 'Image') {
        this.Dspubsub.emit('message', message);
      }
      if (type == 'RichLink') {
        this.Dspubsub.emit('message', message);
      }
      if (type == 'Audio') {
        this.Dspubsub.emit('message', message);
      }
      if (type == 'SysText') {
        this.Dspubsub.emit('message', message);
      }
      if (type == 'Finish') {
        this.Dspubsub.emit('message', message);
      }
      if (type == 'FinishNoReply') {
        this.Dspubsub.emit('message', message);
      }
      if (type == 'Template') {
        this.Dspubsub.emit('message', message);
      }
      if (type == 'Video') {
        this.Dspubsub.emit('message', message);
      }
    });
  }

  async sendMessage(messageObj) {
    //发送消息到Core 异步转同步 直接做Promise通知业务层是否成功 业务层不关注ACK

    /* if (this.core.readyState != 1) {
      //发送消息如果连接已断开直接失败
      return false;
    } */
    const { clientMsgId } = messageObj; //获取发送时客户端ID 用来辨别接收消息时哪条是自己的
    let sendMessage = JSON.parse(JSON.stringify(messageObj)); //深拷贝处理一下引用问题
    if (sendMessage.type == 'Image') {
      //图片需要特殊处理 绝对地址转相对地址 因为客户端接收的时候需要接相对地址
      sendMessage.body.fileUrl = utils.cutUrlHeader(sendMessage.body.fileUrl);
      sendMessage.body.thumbnail = utils.cutUrlHeader(sendMessage.body.thumbnail);
    }
    const str = JSON.stringify({ ...sendMessage });
    this.core.send(str);
    let res = await this.ReceiveREVHand(clientMsgId); //REV认证去了 认证完才能通知客户端是否成功or失败
    return res;
  }
  async ReceiveREVHand(clientMsgId) {
    return new Promise((res, rej) => {
      const _this = this;
      //接收REV处理 将发送分离异步请求转为同步 当客户端在超过指定时间后没有返回ACK则认定该请求失败
      const handler = (ev) => {
        const message = JSON.parse(ev.data); //消息本体 json需要转成对象后才可以继续操作
        const { type } = message;
        if (type == 'REV') {
          //只有客户端用户消息才会有REV
          if (clientMsgId == message.clientMsgId) {
            //同一ID 认为服务器接收客户端消息成功
            this.core.pubsub.off('message', handler);
            res(true);
          }
        }
      };
      setTimeout(() => {
        //2S超时
        // this.core.pubsub.oneOff('message', handler);
        res(false);
      }, 10000);
      //存在重复监听的问题 没啥好方法解决 不单独监听无法直接转为异步 因为无法在拦截后再次启动 不能使用once解决因为core不关注你收到的消息能不能解决的你的问题 你可能收到的是别的消息 so....

      this.core.pubsub.on('message', handler);
    });
  }
}
export default DrugStore;
