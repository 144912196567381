import cDrugStore from '@/drugStore/profession/cDrugStore';
import axios from 'axios';
import mpAdapter from 'axios-miniprogram-adapter';
import constant from '@/utils/constant';
import { version, name } from '../../package.json';
import sdkConst from '@/utils/imSDKConst';
import { api } from '@/api/api';
import { genSDKInfo } from '@/utils/index';

const linkUp = {
  sdkBaseInfo: genSDKInfo(version, name),
  async setEnv(config) {
    //设置环境
    constant.env = config.env;
    if (constant.env == constant.runtimeEnv.weApp) {
      //如果在微信小程序中 处理axios兼容到小程序可以使用
      axios.defaults.adapter = mpAdapter;
    }
    constant.__linkUpRuntime = config.__linkUpRuntime;
    constant.sdkVersionString = `cDrugStore`;
    const r = await api.getZhiyunConfig();
    return r;
  },
  cDrugStore(params) {
    //初始化处方平台三方IM
    return new cDrugStore(params);
  },
  api,
  sdkConst
};
export default linkUp;
